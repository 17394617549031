import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import OpinionCard from './OpinionCard';
import DescriptionDetails from './DescriptionDetails';
import DeliveryDetails from './DeliveryDetails';


const DescriptionTabs = ({ opinions }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ marginTop: 4 }}>
            {/* Tabs */}
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Details" />
                <Tab label="Delivery" />
                <Tab label="Opinions" />
            </Tabs>

            {/* Tab Panels */}
            <Box sx={{ padding: 2 }}>
                {selectedTab === 0 && (
                     <DescriptionDetails />
                    )}
                {selectedTab === 1 && (
                    <DeliveryDetails />
                )}
                {selectedTab === 2 && (
                    <Box>
                        {opinions.map((opinion, index) => (
                            <OpinionCard
                                key={index}
                                avatar={opinion.avatar}
                                name={opinion.name}
                                rating={opinion.rating}
                                comment={opinion.comment}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DescriptionTabs;
