import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#5a3c2e", // Your brown color
        },
        secondary: {
            main: "#d4a373", // Supporting color
        },
        background: {
            default: "#f9f9f9", // Light background
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Arial', sans-serif",
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 600,
        },
        body1: {
            fontSize: "1rem",
        },
    },
});

export default theme;
