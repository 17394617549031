import PropTypes from "prop-types";
import styles from "./Footer.module.css";

const Footer = ({ className = "" }) => {
  return (
    <footer className={[styles.footer, className].join(" ")}>
      <div className={styles.footer1} />
      <div className={styles.serviceLinks}>
        <div className={styles.customerService}>Customer Service</div>
        <div className={styles.contactTermsconditionsChangContainer}>
          <span className={styles.contactTermsconditionsChangContainer1}>
            <p className={styles.contact}>Contact</p>
            <p className={styles.contact}>{`Terms&Conditions`}</p>
            <p className={styles.contact}>Change cookie content</p>
            <p className={styles.contact}>FAQ</p>
            <p className={styles.contact}>Gift Card</p>
            <p className={styles.contact}>Planning service</p>
            <p className={styles.contact}>Van hire</p>
            <p className={styles.interiorDesignServices}>
              Interior design services
            </p>
          </span>
        </div>
      </div>
      <div className={styles.helpLinks}>
        <div className={styles.help}>Help</div>
        <div className={styles.trackParcelReturnContainer}>
          <p className={styles.contact}>{`Track Parcel `}</p>
          <p className={styles.contact}>Return policy</p>
          <p className={styles.contact}>Accessibility</p>
          <p className={styles.contact}>Shipping</p>
          <p className={styles.contact}>Privary policy</p>
        </div>
      </div>
      <div className={styles.aboutLinks}>
        <div className={styles.customerService}>About Hombel</div>
        <div className={styles.trackParcelReturnContainer}>
          <p className={styles.contact}>About us</p>
          <p className={styles.contact}>Find Store</p>
          <p className={styles.contact}>Opening hours</p>
          <p className={styles.contact}>Sustainability</p>
          <p className={styles.interiorDesignServices}>All Furniture</p>
        </div>
      </div>
      <div className={styles.addressSocial}>
        <div className={styles.address}>
          <div className={styles.headOffice}>Head Office</div>
          <div className={styles.adress}>
            <span>
              <p className={styles.contact}>
                Hombel Building 27D, Cosmos Square Zenith Crescent, Infinity
                Park Dublin, D11 XR79
              </p>
              <p className={styles.contact}>&nbsp;</p>
              <p className={styles.contact}>
                Contact Customer Service Telephone: 01 7482938
              </p>
              <p className={styles.contact}>Company Reg.: 876452</p>
              <p className={styles.interiorDesignServices}>VAT: 4452768PK</p>
            </span>
          </div>
        </div>
        <div className={styles.social}>
          <img
            className={styles.icons}
            loading="lazy"
            alt=""
            src="/icons.svg"
          />
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
