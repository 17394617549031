/** @format */

import axios from 'axios';

const commonHeaders = {
    'Content-Type': 'application/json',
};

export const chatbotAxios = axios.create({
    baseURL: 'http://34.34.8.146:80',
    headers: commonHeaders,
    timeout: 45000,
    timeoutErrorMessage: 'TIMEOUT',
    withCredentials: true,
})
