import React, { useState, useEffect, useRef } from "react";
import { ResizableBox } from "react-resizable";
import aiIcon from '../../../icons/aiicon.png';
import "react-resizable/css/styles.css";
import "./ChatBot.css";
import { askQuestion } from "../../../api/ChatBotAPI";

import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../theme';


const Chatbot = ({ productId }) => {
    const [messages, setMessages] = useState([
        { text: "Доброго здоров'я! 👋 Я ваш віртуальний консультант. Як я можу вам допомогти?", sender: "bot" }
    ]);

    const [input, setInput] = useState("");
    const [isChatOpen, setIsChatOpen] = useState(false);
    const chatWindowRef = useRef(null);

    const cleanText = (text) => {
        // Remove unwanted prefixes like "Human:" and "Answer:"
        return text.replace(/^(Human:|Answer:)\s*/i, '').trim();
    };

    const handleSendMessage = async () => {
        if (input.trim() === "") return;

        const userMessage = { text: input, sender: "user" };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        const payload = {
            productId: productId,
            question: input,
        };

        setInput("");

        try {
            const response = await askQuestion(payload);
            const botReply = {
                text: cleanText(response.data.answer) || "Sorry, I couldn't get an answer at this time.",
                sender: "bot",
            };
            setMessages((prevMessages) => [...prevMessages, botReply]);
        } catch (error) {
            const errorMessage = {
                text: "Вибачте, сталась халепа, зачекайте, будь-ласка, поки ми вирішимо технічні проблеми",
                sender: "bot",
            };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
    };

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <ThemeProvider theme={theme}>
        <div>
            <div className="chatbot-chat-icon" onClick={() => setIsChatOpen(!isChatOpen)}>
                <img src={aiIcon} alt="T-market Bot" className="chatbot-bot-icon" />
            </div>

            {isChatOpen && (
                <div className="bot-body">
                    <ResizableBox
                        width={300}
                        height={500}
                        minConstraints={[200, 300]}
                        maxConstraints={[600, 700]}
                        resizeHandles={['nw']}
                        className="chatbot-chatbox"
                    >
                        <div className="chatbot-header">
                            <span className="chatbot-bot-name">T-market Bot</span>
                            <span className="chatbot-bot-status">Online</span>
                        </div>
                        <div className="chatbot-chat-window" ref={chatWindowRef}>
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`chatbot-message ${message.sender === 'bot' ? 'chatbot-bot-message' : 'chatbot-user-message'}`}
                                >
                                    {message.text}
                                </div>
                            ))}
                        </div>
                        <div className="chatbot-chat-input">
                            <input
                                type="text"
                                className="chatbot-input"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                                placeholder="Впишіть, будь-ласка, ваше запитання: "
                            />
                            <button className="chatbot-button" onClick={handleSendMessage}>Send</button>
                        </div>
                    </ResizableBox>
                </div>
            )}
        </div>
        </ThemeProvider>
    );
};

export default Chatbot;
