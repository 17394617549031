import React, {useRef} from "react";
import { Box, Grid, Typography, Button, Card, CardContent, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../components/homepage/header/Header";
import Banner from "../components/homepage/header/Banner";
import Footer from "../components/homepage/footer/Footer";
import MidSection from "../components/homepage/midSection/MidSection";
import Carousel from "react-material-ui-carousel";
import products from "./data/productData.js";

const Homepage = () => {
    const navigate = useNavigate();

    // Product Data
    // const products = [
    //     {
    //         id: "66fda8a430e14968f3778e1a",
    //         title: "Диван Soho",
    //         price: "37000 UAH",
    //         images: [
    //             "./images/product1/img.png",
    //             "./images/product1/aiicon.png",
    //             "./images/product1/img_2.png",
    //         ],
    //         description: "A stylish and modern table lamp.",
    //     },
    //     {
    //         id: "66fda81030e14968f3778b86",
    //         title: "Black Washbasin",
    //         price: "7200 UAH",
    //         images: [
    //             "./images/product2/img.png",
    //             "./images/product2/aiicon.png",
    //             "./images/product2/img_2.png",
    //         ],
    //         description: "Elegant black chair for modern interiors.",
    //     },
    //     {
    //         id: "66fda81030e14968f3778b87",
    //         title: "Люстра Mariposa",
    //         price: "19000 UAH",
    //         images: [
    //             "./images/product3/img.png",
    //             "./images/product3/aiicon.png",
    //             "./images/product3/img_2.png",
    //         ],
    //         description: "Comfortable white chair for any room.",
    //     },
    // ];

    const handleProductClick = (productId) => {
        navigate(`/product/${productId}`);
    };

    const productsRef = useRef(null);

    const handleScrollToProducts = () => {
        productsRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box>
            {/* Header */}
            <Header />

            {/* Banner */}
            <Banner onScrollToProducts={handleScrollToProducts} />
            {/* Stylish Boundary */}
            <Box
                sx={{
                    height: "4px",
                    width: "100%",
                    background: "linear-gradient(90deg, #d4a373, #f9f9f9)", // Stylish gradient
                    marginY: 4,
                }}
            />

            {/* MidPart  */}
            <MidSection />


            {/* Stylish Boundary */}
            <Box
                sx={{
                    height: "4px",
                    width: "100%",
                    background: "linear-gradient(90deg, #d4a373, #f9f9f9)", // Stylish gradient
                    marginY: 4,
                }}
            />

            {/* Products Section */}
            <Box ref={productsRef} sx={{ padding: 4 }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "bold",
                        mb: 4,
                        textAlign: "center",
                    }}
                >
                </Typography>
                <Grid container spacing={4}>
                    {products.map((product) => (
                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                            <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                {/* Image Carousel */}
                                <Box sx={{ position: "relative", overflow: "hidden" }}>
                                    <Carousel
                                        indicators={true}
                                        navButtonsAlwaysVisible
                                        autoPlay={false}
                                        sx={{ height: "350px" }}
                                    >
                                        {product.images.map((image, index) => (
                                            <CardMedia
                                                key={index}
                                                component="img"
                                                height="350"
                                                image={image}
                                                alt={`${product.title} - ${index + 1}`}
                                                sx={{
                                                    objectFit: "cover",
                                                }}
                                            />
                                        ))}
                                    </Carousel>
                                </Box>
                                <CardContent sx={{ flexGrow: 1 }}>
                                    {/* Product Title */}
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold", textAlign: "center" }}
                                    >
                                        {product.title}
                                    </Typography>
                                    {/* Product Description */}
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ mb: 2, textAlign: "center" }}
                                    >
                                        {product.description}
                                    </Typography>
                                    {/* Product Price */}
                                    <Typography
                                        variant="h5"
                                        color="primary"
                                        sx={{
                                            textAlign: "center",
                                            //backgroundColor: "var(--color-darkslategray-100)",
                                            color: "#811b08",
                                        }}
                                    >
                                        {product.price}
                                    </Typography>
                                </CardContent>
                                {/* View Details Button */}
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "rgba(90, 60, 46, 0.8)", // Semi-transparent brown
                                        color: "#fff",
                                        borderRadius: "50px", // Circular shape
                                        padding: "10px 30px", // Adds more padding for a rounded button
                                        fontWeight: "bold",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for a stylish look
                                        transition: "all 0.3s ease", // Smooth hover animation
                                        m: 2,
                                        "&:hover": {
                                            backgroundColor: "rgba(90, 60, 46, 1)", // Fully opaque on hover
                                            transform: "scale(1.05)", // Slightly enlarges the button
                                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", // Enhanced shadow on hover
                                        },
                                    }}
                                    onClick={() => handleProductClick(product.id)}
                                >
                                    View Details
                                </Button>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default Homepage;
