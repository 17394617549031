import { Button, AppBar, Toolbar, Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import { useNotification } from "../../../contexts/NotificationContext";


const Header = () => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const { showNotification } = useNotification();

    const handleMenuClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <AppBar
            position="absolute"
            color="transparent"
            elevation={0}
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                padding: 1,
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
                {/* Logo and Menu */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuClick}
                        sx={{ color: "#fff" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{ mt: 1 }}
                    >
                        <MenuItem onClick={handleMenuClose}>Опа</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Опа</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Жопа</MenuItem>
                    </Menu>
                    <Typography
                        variant="h5"
                        sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                        }}
                    >
                        Hombel
                    </Typography>
                </Box>

                {/* Navigation Buttons */}
                <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{ color: "#fff", fontSize: "16px" }}
                    >
                        Contact
                    </Button>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{ color: "#fff", fontSize: "16px" }}
                    >
                        Find Store
                    </Button>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{ color: "#fff", fontSize: "16px" }}
                        startIcon={<FavoriteBorderIcon />}
                    >
                        Favourites
                    </Button>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{ color: "#fff", fontSize: "16px" }}
                        startIcon={<PersonOutlineIcon />}
                    >
                        Log in
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ShoppingCartIcon />}
                        onClick={() => showNotification("This is only demo app, Не тикай хуйню всяку)! 🎉")}
                        sx={{
                            borderRadius: 2,
                            backgroundColor: "#d4a373",
                            color: "#fff",
                            paddingX: 2,
                            fontSize: "16px",
                            "&:hover": {
                                backgroundColor: "#b8845e",
                            },
                        }}
                    >
                        Basket (1)
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
