import React from "react";
import {
    Box,
    Grid,
    Typography,
    ButtonBase,
    Card,
    CardMedia,
    CardContent,
} from "@mui/material";

const MidSection = () => {
    const categories = [
        { title: "Sofas", image: "./images/sofa.png" },
        { title: "Decor", image: "./images/flower.png" },
        { title: "Chairs", image: "./images/chair.png" },
    ];

    const rooms = [
        { title: "Kitchen Room", image: "/images/KitchenRoom.png" },
        { title: "Living Room", image: "/images/LivingRoom.png" },
    ];

    return (
        <Box sx={{ padding: 4 }}>
            {/* Section 1: Shop by Categories */}
            <Box sx={{ marginBottom: 6 }}>
                <Grid container spacing={4} alignItems="center">
                    {/* Left Text Section */}
                    <Grid item xs={12} sm={3}>
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: 2,
                                    position: "relative",
                                }}
                            >
                                Shop by categories
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: "-5px",
                                        left: 0,
                                        width: "50px",
                                        height: "4px",
                                        backgroundColor: "#d4a373",
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ color: "text.secondary", marginBottom: 4 }}
                            >
                                <ButtonBase
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                        color: "#d4a373",
                                        "&:hover": { textDecoration: "underline" },
                                    }}
                                >
                                    All categories →
                                </ButtonBase>
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Right Images Section */}
                    <Grid item xs={12} sm={9}>
                        <Box sx={{ display: "flex", gap: 3, justifyContent: "center" }}>
                            {categories.map((category, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            aspectRatio: "1 / 1", // Makes the container square
                                            width: "500px", // Adjust this size as needed
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: 2, // Rounded corners
                                            overflow: "hidden",
                                            boxShadow: 3,
                                        }}
                                    >
                                        <img
                                            src={category.image}
                                            alt={category.title}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginTop: 1,
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {category.title}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>










            {/* Section 2: Shop by Rooms */}
            <Grid container spacing={4}>
                {rooms.map((room, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <ButtonBase
                            sx={{
                                width: "100%",
                                height: "600px",
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="900"
                                image={room.image}
                                alt={room.title}
                                sx={{ objectFit: "cover", filter: "brightness(0.8)" }}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    textAlign: "center",
                                    color: "#fff",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        marginBottom: 1,
                                    }}
                                >
                                    {room.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                                >
                                    Shop by room
                                </Typography>
                            </Box>
                        </ButtonBase>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MidSection;
