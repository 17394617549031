import React from 'react';
import { Box, Typography, Avatar, Rating, Divider, Button } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const OpinionCard = ({ avatar, name, rating, comment, date, advantages, defects }) => {
    return (
        <Box
            sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                padding: 2,
                marginBottom: 3,
                backgroundColor: '#fff',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Header Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar alt={name} src={avatar} sx={{ width: 50, height: 50 }} />
                    <Box>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {date}
                        </Typography>
                    </Box>
                </Box>
                <MoreVertIcon sx={{ color: '#9e9e9e', cursor: 'pointer' }} />
            </Box>

            {/* Rating */}
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Rating value={rating} readOnly size="small" />
            </Box>

            {/* Comment Section */}
            <Typography variant="body2" sx={{ marginBottom: 2 }}>
                {comment}
            </Typography>

            {/* Advantages and Defects */}
            {advantages && (
                <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" fontWeight="bold">
                        Advantages:
                    </Typography>
                    <Typography variant="body2">{advantages}</Typography>
                </Box>
            )}
            {defects && (
                <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" fontWeight="bold">
                        Defects:
                    </Typography>
                    <Typography variant="body2">{defects}</Typography>
                </Box>
            )}

            <Divider sx={{ marginY: 2 }} />

            {/* Actions Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                    variant="text"
                    size="small"
                    startIcon={<ThumbUpIcon />}
                    sx={{ color: '#9e9e9e' }}
                >
                    0
                </Button>
                <Button
                    variant="text"
                    size="small"
                    startIcon={<ThumbDownIcon />}
                    sx={{ color: '#9e9e9e' }}
                >
                    0
                </Button>
                <Button variant="text" size="small" sx={{ color: '#9e9e9e' }}>
                    Answer
                </Button>
            </Box>
        </Box>
    );
};

export default OpinionCard;
