import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import ProductImage from "../components/productdetails/ProductImage";
import BuyButton from "../components/productdetails/BuyButton";
import DescriptionTabs from "../components/productdetails/DescriptionTabs";
import products from "./data/productData.js";
import {useParams} from "react-router-dom";
import Chatbot from "../components/homepage/chatbot/ChatBot";


const ProductInfo = () => {

    const handleBuyClick = () => {
        alert('Item added to cart!');
    };

    const { productId } = useParams();
    console.log("Product ID from URL:", productId);
    console.log("Products array:", products);

    const product = products.find((p) => p.id === productId);
    console.log("Matched Product:", product);
    if (!product) {
        return <Typography variant="h5">Product not found</Typography>;
    }

    const opinions = [
        {
            avatar: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fbubble.io%2Fplugin%2Falphabet-profile-images-1479946498882x370599375215525900&psig=AOvVaw06aKq4JTfCZan6N1ScMQS_&ust=1732913027794000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPjDg57y_4kDFQAAAAAdAAAAABAE',
            name: 'Alexa Doski',
            rating: 5,
            comment: 'Super',
            date: 'February 12, 2024',
            advantages: 'High quality and performance',
            defects: 'There is no',
        },
        {
            avatar: 'https://via.placeholder.com/50',
            name: 'Simon',
            rating: 3,
            comment:
                '- Tested multiple cables - even those with higher bandwidth than 18W. Charging speed results shown by AccuBattery app.',
            date: 'February 12, 2024',
            advantages: 'Cool look and capacity',
            defects:
                '- 18W is the sum (exaggerated) of two ports (something like that is even written in the instructions). One of the ports charges slower than the other one.',
        },
        {
            avatar: 'https://via.placeholder.com/50',
            name: 'Maksim Tretyak',
            rating: 5,
            comment: 'Overall, I’m happy with everything.',
            date: 'February 27, 2023',
            advantages: 'Good build quality',
            defects: null,
        },
    ];


    return (
        <Box sx={{ padding: 2, maxWidth: '1200px', margin: 'auto' }}>
            <Grid container spacing={4}>

                {/* Chatbot Section */}
                <Grid item xs={12} md={3}>
                    <Box sx={{ position: "sticky", top: 100 }}> {/* Sticky position for chatbot */}
                        <Chatbot productId={product.id} />
                    </Box>
                </Grid>

                {/* Image and Description Section */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ProductImage images={product.images} />
                        {/* Buy Section */}
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant="h4" gutterBottom>
                                    {product.title}
                                </Typography>
                            </Box>
                            <BuyButton price={product.price} onClick={() => alert("Item added to cart!")} />
                        </Grid>
                        <DescriptionTabs opinions = {product.opinions} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};


export default ProductInfo;
