import {chatbotAxios} from './Axios';

/**
 * Make a GET request to the /ask endpoint with a JSON body and custom headers.
 * @param {Object} payload - The JSON object containing productId and question.
 * @returns {Promise} - The Axios response from the endpoint.
 */
export const askQuestion = async (payload) => {
    try {
        return await chatbotAxios({
            url: '/ask',
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'cache': 'false',
            },
            data: payload,
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
