import {
    Routes,
    Route, Router,
} from "react-router-dom";
import Homepage from "./containers/Homepage";
import ProductInfo from './containers/ProductInfo';
import theme from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import NotificationProvider from "./contexts/NotificationContext.js";


function App() {

    return (
        <ThemeProvider theme={theme}>
            <NotificationProvider>
                <Routes>
                    <Route path="/" element={<Homepage/>}/>
                    <Route path="/product" element={<ProductInfo/>}/>
                    <Route path="/product/:productId" element={<ProductInfo/>}/>
                </Routes>
            </NotificationProvider>

        </ThemeProvider>
    );

}

export default App;
