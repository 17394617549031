//simulation of DB
// productData.js
const products = [
    {
        id: "66fda8a430e14968f3778e1a",
        title: "Диван Soho",
        price: "37000 UAH",
        images: [
            "/images/product1/img.png",
            "/images/product1/img_1.png",
            "/images/product1/img_2.png",
        ],
        description: "A stylish and modern table lamp.",
        opinions: [
            {
                avatar: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fbubble.io%2Fplugin%2Falphabet-profile-images-1479946498882x370599375215525900&psig=AOvVaw06aKq4JTfCZan6N1ScMQS_&ust=1732913027794000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPjDg57y_4kDFQAAAAAdAAAAABAE',
                name: 'Alexa Doski',
                rating: 5,
                comment: 'Super',
                date: 'February 12, 2024',
                advantages: 'High quality and performance',
                defects: 'There is no',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Simon',
                rating: 3,
                comment:
                    '- Tested multiple cables - even those with higher bandwidth than 18W. Charging speed results shown by AccuBattery app.',
                date: 'February 12, 2024',
                advantages: 'Cool look and capacity',
                defects:
                    '- 18W is the sum (exaggerated) of two ports (something like that is even written in the instructions). One of the ports charges slower than the other one.',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Maksim Tretyak',
                rating: 5,
                comment: 'Overall, I’m happy with everything.',
                date: 'February 27, 2023',
                advantages: 'Good build quality',
                defects: null,
            },
        ]
    },
    {
        id: "66fda81030e14968f3778b86",
        title: "Black Washbasin",
        price: "7200 UAH",
        images: [
            "/images/product2/img.png",
            "/images/product2/img_1.png",
            "/images/product2/img_2.png",
        ],
        description: "Elegant black chair for modern interiors.",
        opinions: [
            {
                avatar: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fbubble.io%2Fplugin%2Falphabet-profile-images-1479946498882x370599375215525900&psig=AOvVaw06aKq4JTfCZan6N1ScMQS_&ust=1732913027794000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPjDg57y_4kDFQAAAAAdAAAAABAE',
                name: 'Alexa Doski',
                rating: 5,
                comment: 'Super',
                date: 'February 12, 2024',
                advantages: 'High quality and performance',
                defects: 'There is no',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Simon',
                rating: 3,
                comment:
                    '- Tested multiple cables - even those with higher bandwidth than 18W. Charging speed results shown by AccuBattery app.',
                date: 'February 12, 2024',
                advantages: 'Cool look and capacity',
                defects:
                    '- 18W is the sum (exaggerated) of two ports (something like that is even written in the instructions). One of the ports charges slower than the other one.',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Maksim Tretyak',
                rating: 5,
                comment: 'Overall, I’m happy with everything.',
                date: 'February 27, 2023',
                advantages: 'Good build quality',
                defects: null,
            },
        ]
    },
    {
        id: "66fda81030e14968f3778b87",
        title: "Люстра Mariposa",
        price: "19000 UAH",
        images: [
            "/images/product3/img.png",
            "/images/product3/img_1.png",
            "/images/product3/img_2.png",
        ],
        description: "Comfortable white chair for any room.",
        opinions: [
            {
                avatar: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fbubble.io%2Fplugin%2Falphabet-profile-images-1479946498882x370599375215525900&psig=AOvVaw06aKq4JTfCZan6N1ScMQS_&ust=1732913027794000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPjDg57y_4kDFQAAAAAdAAAAABAE',
                name: 'Alexa Doski',
                rating: 5,
                comment: 'Super',
                date: 'February 12, 2024',
                advantages: 'High quality and performance',
                defects: 'There is no',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Simon',
                rating: 3,
                comment:
                    '- Tested multiple cables - even those with higher bandwidth than 18W. Charging speed results shown by AccuBattery app.',
                date: 'February 12, 2024',
                advantages: 'Cool look and capacity',
                defects:
                    '- 18W is the sum (exaggerated) of two ports (something like that is even written in the instructions). One of the ports charges slower than the other one.',
            },
            {
                avatar: 'https://via.placeholder.com/50',
                name: 'Maksim Tretyak',
                rating: 5,
                comment: 'Overall, I’m happy with everything.',
                date: 'February 27, 2023',
                advantages: 'Good build quality',
                defects: null,
            },
        ]
    },
];

module.exports = products;