import React from 'react';
import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';


const ProductImage = ({ images }) => {
    // Slick slider settings

    return (
        <Carousel
            indicators={true}
            animation="slide"
            navButtonsAlwaysVisible
            autoPlay
            sx={{
                width: '100%',
                height: '500px', // Adjust this to make the carousel taller
            }}
        >
            {images.map((image, index) => (
                <Box
                    key={index}
                    component="img"
                    src={image}
                    alt={`Product Image ${index + 1}`}
                    sx={{
                        width: '100%',
                        height: '400px',
                        objectFit: 'contain',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2,
                    }}
                />
            ))}
        </Carousel>
    );
};

export default ProductImage;
