import React from 'react';
import { Box, Typography, List, ListItem, Divider } from '@mui/material';

const DescriptionDetails = () => {
    return (
        <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            {/* Title */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Description
            </Typography>

            {/* Highlights */}
            <Typography variant="body1" color="error" gutterBottom>
                Adjustable hooks are perfect for organizing your space.
            </Typography>
            <Typography variant="body1" color="error" gutterBottom>
                Durable steel and wood design to support heavy clothes.
            </Typography>

            {/* Main Description */}
            <Typography variant="body1" paragraph>
                The IKEA Clothes Hanger is the ultimate solution for organizing your wardrobe.
                Designed with high-quality steel and sustainable wood, it is perfect for hanging your
                clothes, jackets, or bags. Whether used in your bedroom or entryway, the sleek
                Scandinavian design fits seamlessly into any decor style.
            </Typography>

            {/* Characteristics */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Characteristics:
            </Typography>
            <List>
                <ListItem>- Material: Steel and sustainable wood</ListItem>
                <ListItem>- Adjustable hooks for varied clothing</ListItem>
                <ListItem>- Weight capacity: 15 kg</ListItem>
                <ListItem>- Available in multiple finishes: Black, White, Oak</ListItem>
                <ListItem>- Dimensions: Height 175 cm, Width 45 cm</ListItem>
            </List>

            {/* Input Parameters */}
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ marginTop: 2 }}>
                Dimensions:
            </Typography>
            <List>
                <ListItem>- Height: 175 cm</ListItem>
                <ListItem>- Width: 45 cm</ListItem>
                <ListItem>- Base diameter: 35 cm</ListItem>
            </List>

            {/* Output Parameters */}
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ marginTop: 2 }}>
                Features:
            </Typography>
            <List>
                <ListItem>- Anti-slip hooks to secure clothes</ListItem>
                <ListItem>- Easy assembly with included tools</ListItem>
                <ListItem>- Lightweight and portable design</ListItem>
                <ListItem>- Rust-resistant coating for longevity</ListItem>
            </List>

            {/* Final Notes */}
            <Typography variant="body1" paragraph sx={{ marginTop: 2 }}>
                Perfect for all seasons, the IKEA Clothes Hanger can hold heavy winter coats or lightweight
                summer dresses. Its adjustable hooks make it easy to customize for your unique needs.
            </Typography>

            <Divider sx={{ marginY: 2 }} />

            {/* Specifications */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Specification:
            </Typography>
            <List>
                <ListItem>- Product Type: Clothes Hanger</ListItem>
                <ListItem>- Material: Steel and wood</ListItem>
                <ListItem>- Weight Capacity: 15 kg</ListItem>
                <ListItem>- Warranty: 2 years</ListItem>
            </List>
        </Box>
    );
};

export default DescriptionDetails;
