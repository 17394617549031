import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';

const DeliveryDetails = () => {
    return (
        <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            {/* Title */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Delivery Information
            </Typography>

            {/* Main Information */}
            <Typography variant="body1" paragraph>
                Our delivery service ensures your product arrives safely and on time. With multiple
                options available, you can choose the delivery method that suits your needs best.
            </Typography>

            {/* Delivery Options */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Delivery Options:
            </Typography>
            <List>
                <ListItem>- Standard Delivery: 3-5 business days</ListItem>
                <ListItem>- Express Delivery: 1-2 business days</ListItem>
                <ListItem>- Pickup Point: Available within 2 business days</ListItem>
                <ListItem>- Same-Day Delivery: Available for select locations</ListItem>
            </List>

            {/* Additional Notes */}
            <Typography variant="body1" paragraph sx={{ marginTop: 2 }}>
                All delivery options are available with real-time tracking so you can monitor your
                order every step of the way. Express and same-day delivery are subject to additional
                charges based on location and item size.
            </Typography>

            <Typography variant="body1" paragraph>
                For international shipping, please allow 7-14 business days. Customs fees may apply
                depending on your country.
            </Typography>
        </Box>
    );
};

export default DeliveryDetails;
